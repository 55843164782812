export const environment = {
  production: true,
  isServer: true,
  version: 1.0,
  // for prerender

  host: 'https://henra.temared.com',
  cdn: 'https://henra.temared.com/uploads',
  api: 'https://henra.temared.com/app',
  socket: 'https://peer.digitalgrid.pl',
  base: 'https://henra.temared.com',
  name: 'Henra',
  currencies: ['EUR', 'PLN', 'GBP'],
  withavatar: false,
  events_exclude: [''],
  inpost_src: 'https://geowidget.inpost.pl/inpost-geowidget.js',
  inpost_css: 'https://geowidget.inpost.pl/inpost-geowidget.css',
  inpost: 'x'
};

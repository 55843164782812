import { enableProdMode } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

let locale ='nl';// window.document.documentElement.lang;

if (locale === 'pl') {
  bootstrap();
  // fetch(`${environment.cdn}/i18n/messages.en.json`)
  //   .then((response) => {
  //     if (!response.ok) {
  //       throw new Error(`HTTP error ${response.status}`);
  //     }
  //     return response.json();
  //   })
  //   .then((result) => {
  //     loadTranslations(result);
  //   }).then(() => bootstrap());
} else {
  // fetch(`//cms.local.pl/uploads/i18n/messages.${locale}.json`)
  fetch(`${environment.cdn}/i18n/messages.${locale}.json`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }
      return response.json();
    })
    .then((result) => {
      loadTranslations(result);
    }).then(() => bootstrap());
}

export function bootstrap() {
  import('./bootstrap').then((m) => m.bootstrap());
}
